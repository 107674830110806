/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.1/dist/jquery.min.js
 * - /npm/bootstrap@5.3.0/dist/js/bootstrap.min.js
 * - /npm/@popperjs/core@2.11.6/dist/umd/popper.min.js
 * - /npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js
 * - /npm/waypoints@4.0.1/lib/jquery.waypoints.min.js
 * - /npm/waypoints@4.0.1/src/shortcuts/sticky.min.js
 * - /npm/counterup@1.0.0/jquery.counterup.min.js
 * - /npm/sticky-js@1.3.0/dist/sticky.min.js
 * - /npm/isotope-layout@3.0.6/dist/isotope.pkgd.min.js
 * - /npm/imagesloaded@4.1.4/imagesloaded.pkgd.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/jsrender@1.0.14/jsrender.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
